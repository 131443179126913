<template>
  <div class="alert_wrap" style="width:400px;">
    <!-- alert_wrap    -->
    <div class="message left">
      <!-- message -->
      {{ this.$t('msg.ONIM050P020.006') }}<br />
      {{ this.$t('msg.ONIM050P020.007') }}<br />
      {{ this.$t('msg.ONIM050P020.008') }}
    </div><!-- message // -->

    <div class="btn_set dv2">
      <ul>
        <li><a class="button blue lg" href="#" @click.prevent="sendEmail()">{{ $t('msg.SETT060G010.020') }}</a></li>
        <li><a class="button gray lg" href="#" @click.prevent="$emit('close')">{{ $t('msg.SETT060G010.021') }}</a></li>
      </ul>
    </div>
  </div><!-- alert_wrap // -->
</template>

<script>
import surrenders from '@/api/rest/trans/surrenders'

export default {
  name: 'SurrenderReqEmailPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          voyNo: '',
          vslNm: '',
          podPortCd: ''
        }
      }
    }
  },
  data () {
    return {
      formData: {
        blNo: '',
          voyNo: '',
          vslNm: '',
          podPortCd: ''
      }
    }
  },
  created () {
    const parentInfo = this.parentInfo
    if (this.$ekmtcCommon.isNotEmpty(parentInfo.blNo)) {
      this.formData = parentInfo
    } else if (parentInfo.surrEmailyn === 'Y') {
      this.$ekmtcCommon.alertCallback(this.$t('msg.ONIM050P020.001'), function () {
        TH.$emit('close')
      })
    } else if (parentInfo.doStsCd === '1' || parentInfo.doStsCd === '2') {
      this.$ekmtcCommon.alertCallback(this.$t('msg.ONIM050P020.002'), function () {
        TH.$emit('close')
      })
    } else {
      this.$ekmtcCommon.alertCallback(this.$t('msg.ONIM050P020.003'), function () {
        TH.$emit('close')
      })
    }
  },
  methods: {
    sendEmail () {
      surrenders.sendSurrenderEmail(this.formData).then(res => {
        if (res.headers.respcode === 'C0000') {
          const TH = this
          TH.$ekmtcCommon.alertCallback(TH.$t('msg.ONIM050P020.004'), () => {
            TH.$emit('close')
          })
        } else {
          this.$ekmtcCommon.alertCallback(this.$t('msg.ONIM050P020.005'), function () {
            // this.$emit('close')
          })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
