var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "alert_wrap", staticStyle: { width: "400px" } },
    [
      _c("div", { staticClass: "message left" }, [
        _vm._v(" " + _vm._s(this.$t("msg.ONIM050P020.006"))),
        _c("br"),
        _vm._v(" " + _vm._s(this.$t("msg.ONIM050P020.007"))),
        _c("br"),
        _vm._v(" " + _vm._s(this.$t("msg.ONIM050P020.008")) + " "),
      ]),
      _c("div", { staticClass: "btn_set dv2" }, [
        _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                staticClass: "button blue lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.sendEmail()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.SETT060G010.020")))]
            ),
          ]),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "button gray lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.SETT060G010.021")))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }